import React, { useEffect } from "react"
import { navigate } from "gatsby";

const MarketGuide = () => {
    useEffect(() => navigate('/market-guide/argentina'), []);

    return <div />;
}

export default MarketGuide;
